// 特定のアンケートのフォーム

<template lang="pug">
el-card.survey-form

  .go-back(style='margin-bottom: 0.5rem')
    el-button(type='text', icon='el-icon-back', @click='$router.go(-1)') 戻る

  h3 「{{ title }}」のフォーム

  .header
    el-button(
      type='primary',
      icon='el-icon-circle-plus',
      plain,
      @click='addItem(true)'
    ) 項目を先頭に追加

  article

    ul(v-if='formItems.length > 0')
      li(
        v-for='item, i in formItems',
        :key='item.id'
      )
        form-edit-item(
          :value='item',
          @input='updateItem(i, $event)'
          @move-up='moveUpItem(i)',
          @move-down='moveDownItem(i)'
          @remove='removeItem(i)'
        )

    el-alert(
      v-else,
      title='質問項目がありません。',
      type='warning',
      :closable='false'
    )

  .footer
    el-button(
      type='primary',
      icon='el-icon-circle-plus',
      plain,
      @click='addItem(false)'
    ) 項目を末尾に追加

  footer
    el-button(
      @click='$router.go(-1)'
    ) 前のページへ
    el-button(
      type='warning',
      @click='showPreview'
    ) プレビュー
    el-button(
      type='primary',
      @click='saveForm'
    ) 内容を更新

  form-preview-dialog(
    :visible.sync='formPreviewDialogVisible',
    :items='formItems'
  )
</template>

<script>
import FormEditItem from '@/components/form-edit-item'

import FormPreviewDialog from '@/dialogs/form-preview-dialog'

import getSurveyApi from '@/api/admin/get-survey'
import updateSurveyFormApi from '@/api/admin/update-survey-form'

export default {
  name: 'AdminSurveyForm',

  components: {
    FormEditItem,
    FormPreviewDialog,
  },

  data() {
    return {
      formItems: [],
      title: '',
      formPreviewDialogVisible: false,
    }
  },

  computed: {
    surveyId() {
      return parseInt(this.$route.params.surveyId)
    },
  },

  async created() {
    const response = await getSurveyApi(this.surveyId)
    if (!response.ok) return
    this.title = response.payload.title
    if (response.payload.form) {
      this.formItems = response.payload.form
    }
  },

  methods: {
    addItem(isAddingToTop = false) {
      if (!isAddingToTop) {
        this.formItems.push({
          // 重複しない(可能性が極めて高い)Idを振る
          id: `q-${Date.now()}${Math.floor(Math.random() * 9)}`,
        })
      } else {
        this.formItems.unshift({
          // 重複しない(可能性が極めて高い)Idを振る
          id: `q-${Date.now()}${Math.floor(Math.random() * 9)}`,
        })
      }
    },

    moveUpItem(index) {
      if (index === 0) return
      const item = this.formItems[index]
      this.formItems.splice(index, 1)
      this.formItems.splice(index - 1, 0, item)
    },

    moveDownItem(index) {
      if (index + 1 === this.formItems.length) return
      this.moveUpItem(index + 1)
    },

    async removeItem(index) {
      try {
        await this.$confirm('本当に削除してもよろしいですか？', '確認', {
          confirmButtonText: '削除',
          cancelButtonText: 'キャンセル',
        })
        this.formItems.splice(index, 1)
      } catch (e) {
        // 何もしない
      }
    },

    async saveForm() {
      // typeが未設定のものは除去
      const normalized = this.formItems.filter((item) => item.type)
      const response = await updateSurveyFormApi(this.surveyId, normalized)
      if (!response.ok) return
      try {
        await this.$confirm('内容の更新に成功しました。一覧に戻りますか？', '更新成功', {
          confirmButtonText: '一覧に戻る',
          cancelButtonText: '留まる',
          type: 'success',
        })
        this.$router.go(-1)
      } catch (e) {
        // 何もしない
      }
    },

    updateItem(i, newItem) {
      const item = Object.assign({ id: this.formItems[i].id }, newItem)
      this.formItems.splice(i, 1, item)
    },

    showPreview() {
      this.formPreviewDialogVisible = true
    },
  },
}
</script>

<style lang="sass" scoped>
.survey-form

  .header
    margin-top: 1rem

  article
    margin-top: 1rem
    ul
      list-style-type: none
      li
        &:nth-child(n+2)
          margin-top: 1rem

  .footer
    margin-top: 1rem

  footer
    border-top: 1px solid #ccc
    margin-top: 1rem
    padding-top: 1rem
    text-align: center
</style>
