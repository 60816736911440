// フォームの要素

<template lang="pug">
.form-edit-item

  el-row.row(
    type='flex'
  )

    el-col.left-side(:span='8')
      .wrapper
        el-select(
          v-model='form.type'
        )
          el-option(
            v-for='item in types',
            :key='item.value',
            :label='item.name',
            :value='item.value'
          )
        .options(v-if='isEditable(form.type)')
          el-checkbox(v-model='form.required') 必須
          el-checkbox(v-model='hasNote') 備考欄
      a.move-up(@click='$emit("move-up")')
        i.el-icon-caret-top
      a.move-down(@click='$emit("move-down")')
        i.el-icon-caret-bottom

    el-col.right-side(:span='16')
      .wrapper
        el-form(
          v-if='form.type'
        )

          el-form-item(
            v-if='form.type === "heading"'
            label='見出し'
          )
            el-input(v-model='form.caption')

          el-form-item(
            v-if='form.type === "message" || form.type === "agreement"'
            label='説明文'
          )
            el-input(
              type='textarea',
              :rows='3',
              v-model='form.caption'
            )

          el-form-item(
            v-if='isEditable(form.type)',
            label='項目名 / 質問文'
          )
            el-input(v-model='form.caption')

          el-form-item(
            v-if='form.type === "line"'
            label='入力条件'
          )
            el-radio-group(v-model='form.validator')
              el-radio(
                v-for='item in validators',
                :key='item.value',
                :label='item.value'
              ) {{ item.name }}

          el-form-item(
            v-if='form.type === "single" || form.type === "multiple" || form.type === "table"',
            :label='`${form.type === "table" ? "小項目" : "選択項目"}(句読点・カンマ・改行で区切る)`'
          )
            el-input(
              type='textarea',
              :rows='1',
              autosize,
              placeholder='(例)年少,年中,年長'
              v-model='colsStr'
            )

          el-form-item(
            v-if='form.type === "matrix"',
            label='列の項目'
          )
            el-input(
              type='textarea',
              :rows='1',
              autosize,
              placeholder='(例)年少,年中,年長'
              v-model='colsStr'
            )

          el-form-item(
            v-if='form.type === "matrix"',
            label='行の項目'
          )
            el-input(
              type='textarea',
              :rows='1',
              autosize,
              placeholder='(例)A班,B班,C班'
              v-model='rowsStr'
            )

          el-form-item(
            v-if='form.type === "table"',
            label='入力値の重複'
          )
            el-checkbox(v-model='form.unique') 重複を許可しない

          //- 説明要素において別スタイルとするか
          el-checkbox(
            v-if='form.type === "message"',
            v-model='form.isAnotherStyle'
          ) 背景色なし

          el-form-item(
            v-if='hasNote'
            label='備考'
          )
            el-input(type='textarea', v-model='form.note', :rows='2')
        p(v-else)
          | 左の選択ボックスから項目の種類を選んでください。

      a.remove(@click='$emit("remove")')
        i.el-icon-error
</template>

<script>
import pickBy from 'lodash.pickby'
import isNil from 'lodash.isnil'

const types = [
  { name: '数値', value: 'number' },
  { name: '1行入力', value: 'line' },
  { name: '複数行入力', value: 'multiline' },
  { name: '単一選択', value: 'single' },
  { name: '複数選択', value: 'multiple' },
  { name: '表(1列・数値用)', value: 'table' },
  { name: '表(縦x横・数値用)', value: 'matrix' },
  { name: '名前', value: 'name' },
  { name: 'ふりがな(ひらがな)', value: 'kana' },
  { name: '園の選択', value: 'org' },
  { name: '説明', value: 'message' },
  { name: '見出し', value: 'heading' },
  { name: '同意チェックボックス', value: 'agreement' },
]

const validators = [
  { name: 'なし', value: null },
  { name: '半角英数字', value: 'hankaku' },
  { name: 'メールアドレス', value: 'mail' },
  { name: '電話番号', value: 'tel' },
]

export default {
  name: 'FormEditItem',

  props: {
    value: {
      type: Object,
    },
  },

  data() {
    return {
      form: {
        type: null,
        required: false,
        caption: null,
        unique: false,
        validator: null,
        note: null,
        // 別の見た目で表示するか
        // 現時点では「説明(message)」でしか対応していない
        isAnotherStyle: false,
      },
      hasNote: false,
      // 列名
      colsStr: null,
      // 行名
      rowsStr: null,
    }
  },

  created() {
    if (!this.value.type) return
    this.form.type = this.value.type
    if (!isNil(this.value.required)) this.form.required = this.value.required
    if (!isNil(this.value.caption)) this.form.caption = this.value.caption
    if (!isNil(this.value.unique)) this.form.unique = this.value.unique
    if (!isNil(this.value.validator)) this.form.validator = this.value.validator
    if (!isNil(this.value.options)) this.colsStr = this.value.options.join(', ')
    if (!isNil(this.value.rows)) this.rowsStr = this.value.rows.join(', ')
    if (!isNil(this.value.note)) {
      this.hasNote = true
      this.form.note = this.value.note
    }
    if (!isNil(this.value.isAnotherStyle)) this.form.isAnotherStyle = this.value.isAnotherStyle
  },

  computed: {
    types() {
      return types
    },
    validators() {
      return validators
    },
  },

  methods: {
    isEditable(type) {
      if (!this.form.type) return false
      return ['heading', 'agreement', 'message'].indexOf(type) === -1
    },
  },

  updated() {
    const form = pickBy(this.form, (item) => !isNil(item))
    if (this.colsStr) {
      form.options = this.colsStr
        .split(/[,\n、，]/)
        .map((val) => val.trim())
        .filter((val) => val)
    }
    if (this.rowsStr) {
      form.rows = this.rowsStr
        .split(/[,\n、，]/)
        .map((val) => val.trim())
        .filter((val) => val)
    }
    if (!this.hasNote && form.note) delete form.note
    this.$emit('input', form)
  },

  watch: {
    // タイプの変更を監視して、
    // 名前 が選択された場合、captionに'氏名'を
    // ふりがな が選択された場合、captionに'ふりがな'を入力しておく
    'form.type'() {
      if (this.form.type === 'name' && !this.form.caption) this.form.caption = '氏名'
      else if (this.form.type === 'kana' && !this.form.caption) this.form.caption = 'ふりがな'
      else if (this.form.type === 'org' && !this.form.caption) this.form.caption = '園名'
    },
  },
}
</script>

<style lang="sass" scoped>
.form-edit-item

  .row
    align-items: stretch

    .left-side
      background: #d8d8d8
      border-top-left-radius: 5px
      border-bottom-left-radius: 5px
      display: flex
      align-items: center
      justify-content: center
      position: relative
      .wrapper
        padding: 2rem 0.5rem
        text-align: center
      > a
        display: block
        color: #666
        font-size: 1.6rem
        line-height: 1
        position: absolute
        left: 0.3rem
        cursor: pointer
        &:hover
          color: #f9963b
        &.move-up
          top: 0
        &.move-down
          bottom: 0

    .right-side
      background: #eee
      border-top-right-radius: 5px
      border-bottom-right-radius: 5px
      position: relative
      .wrapper
        padding: 1rem
      > a.remove
        position: absolute
        top: -3px
        right: -3px
        font-size: 1.3rem
        line-height: 1
        cursor: pointer
        color: #861919
</style>
