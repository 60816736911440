// アンケートのフォームの更新

import api from '../api'

const path = '/admin/surveys/{id}/form'

export default (surveyId, items) => {
  const url = path.replace('{id}', surveyId)
  return api({
    method: 'put',
    url,
    params: {
      items,
    },
    auth: true,
  })
}
