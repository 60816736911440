// 管理者向けのフォームの確認ダイアログ

<template lang="pug">
el-dialog.form-preview-dialog(
  title='フォームの内容確認',
  width='90%',
  :visible='visible',
  @update:visible='$emit("update:visible", $event)'
)
  article(v-if='visible')
    survey-form(:items='items')
    .buttons
      el-button(
        @click='close()'
      ) 閉じる
</template>

<script>
import SurveyForm from '@/components/survey-form'

export default {
  name: 'FormPreviewDialog',

  components: {
    SurveyForm,
  },

  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
    },
  },

  methods: {
    close() {
      this.$emit('update:visible', false)
    },
  },
}
</script>

<style lang="sass" scoped>
.form-preview-dialog
  article
    margin-top: -20px
    .buttons
      margin-top: 2rem
</style>
